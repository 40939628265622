import React from "react";

class Item extends React.Component {
  render() {
    return (
      <div style={{ margin: "auto" }}>
        <a href={this.props.url}>
          <img
            className="image"
            style={{ backgroundColor: this.props.color }}
            src={this.props.img}
            alt={this.props.name}
          />
        </a>
      </div>
    );
  }
}
export default Item;
