import React from "react";
import Item from "./Item";

import webmin from "./img/webmin.svg";
import plex from "./img/plex.svg";
import qBit from "./img/qBittorrent.svg";
import AdGuard from "./img/AdGuard.svg";
import nextcloud from "./img/nextcloud.svg";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timer = setInterval(() => this.setState({ date: new Date() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <>
        <div className="bg" />
        <div className="center">
          <h1>Evergarden</h1>
          <h2>{this.state.date.toLocaleTimeString()}</h2>
          <div className="grid-container">
            <Item
              url="https://admin.evergarden.dev/"
              img={webmin}
              name="Webmin"
              color="#FFFFFF"
            />
            <Item
              url="https://alto.evergarden.dev/"
              img={plex}
              name="PLEX"
              color="#282A2D"
            />
            <Item
              url="https://bit.evergarden.dev/"
              img={qBit}
              name="qBittorrent"
              color="#DAEFFF"
            />
            <Item
              url="https://bypass.evergarden.dev/"
              img={AdGuard}
              name="AdGuard"
              color="#FFFFFF"
            />
            <Item
              url="https://cloud.evergarden.dev/"
              img={nextcloud}
              name="NextCloud"
              color="#FFFFFF"
            />
          </div>
        </div>
      </>
    );
  }
}
export default Main;
